import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record         from '../services/S_Record';
// import {I_Record}        from '../services/I_Record';

// Styles
// import './Dialog.scss';

type DialogDetailsCategory1Props = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close
}

function DialogDetailsCategory1(props:DialogDetailsCategory1Props) {

  // props
  let {isDialogOpened,closeDialog} = props;

  // state

  // autres

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="text-center capitalize">PTAE</div>
      </DialogTitle>
      <DialogContent>

        <h3>Les Pressions Thoraco-Abdominales à l'Effort (PTAE) VS VERROUILLAGE SYSTEMATIQUE</h3>
        <p>
          Il s'agit des différentes pressions retrouvées dans la vie quotidienne qui vont pouvoir avoir comme conséquence de « pousser le périnée vers le bas » de manière répétée et fréquente, et ainsi pouvoir être à l'origine d'incontinences ou de descente d'organe (<i>prolapsus, pesanteurs</i>) par affaiblissement progressif du « hamac ». 
          <br/>Avec la rééducation du périnée et l'entraînement régulier vous pourrez/devrez (!) prévenir ces troubles tels que :
        </p>
        <ul>
          <li>Efforts internes : le rire, la toux, les éternuements, le mouchage, le chant, les cris, miction, transit ... On remonte son périnée puis on gaine ses abdo = on inverse le mouvement !</li>
          <li>Efforts externes : le port de charges lourdes (enfant, pack de lait etc...), la zumba, le footing... Verrouillage du périnée, gainage de la sangle abdominale puis effort.</li>
        </ul>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogDetailsCategory1;
