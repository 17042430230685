// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import {I_Record}         from '../services/I_Record';

// Styles
// import './Accordions.scss';

type RecordsAccordionsProps = {
  records: I_Record[];
  editRecord: any;
}

function RecordsAccordions(props:RecordsAccordionsProps) {

  // props
  let {records,editRecord} = props;

  // state



  // autres variables

  let currentDayMillis = S_Date.getCurrentDayReference();

  if(!records)
    return null;

  let recordsByDate = S_Date.groupByDayDate(records);
  if(!recordsByDate)
    return null;

  // return (
  //   <div>
  //     {Object.keys(recordsByDate).map((groupByDayDate,index) => {
  //       let dayItems = recordsByDate[groupByDayDate];
  //       return (
  //         <Accordion key={index} defaultExpanded={false} TransitionProps={{ unmountOnExit: true }}>
  //           <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="capitalize">
  //             <strong>{S_Date.formatDateInStringWithName(parseInt(groupByDayDate))}</strong>
  //             <i className="mh-05">{dayItems.length}</i>
  //           </AccordionSummary>
  //           <AccordionDetails>
  //             <div>
  //               {dayItems.reverse().map((item,index2) => (
  //                 <span key={index2} onClick={(e) => editRecord(item)} className="mr-05">
  //                   {S_Date.formatHourInString(parseInt(item.date))}
  //                 </span>
  //               ))}
  //             </div>
  //           </AccordionDetails>
  //         </Accordion>
  //       );
  //     })}
  //   </div>
  // );
  return (
    <div className="mv-1">
      {Object.keys(recordsByDate).map((groupByDayDate,index) => {
        let dayItems = recordsByDate[groupByDayDate];
        return (
          <div key={index} className="text-left">
            <div className="capitalize">
              <strong>{S_Date.formatDateInStringWithName(parseInt(groupByDayDate))}</strong>
              <i className="mh-05">{dayItems.length}</i>
            </div>
            <div>
              {dayItems.reverse().map((item,index2) => (
                <i key={index2} onClick={(e) => editRecord(item)} className="pr-05">
                  {S_Date.formatHourInString(parseInt(item.date))}
                </i>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RecordsAccordions;
