import S_App    from   '../../services/S_App';
import S_Date   from   '../../services/S_Date';
import S_Record   from   './S_Record';
import {I_Record} from   './I_Record';




let S_Gaming = {
  isLogDebug: S_App.isLogDebug,

  xp: 0,
  diamonds: 0,
  tonus: {
    item01: {
      unlocked: true,
      value: 50,
      name:'Plante 1'
    }
  },
  seriesCountByType: {},
  steps: {
    maxCount_seriesOfOne: 0,
    minCount_seriesOfType2: -1,
    minCount_seriesOfType3: -1,
    level_anyType: 0,
    level_5ofType2: 0,
    level_5ofType3: 0
  },
  level: 0,

  // 1 : 1^3 1series   3xp/1diamonds   6xp
  // 2 : 2^3 8series   24xp/2diamonds
  // 3 : 3^3 27series  81xp/3diamonds
  // 4 : 4^3 64series
  // 5 : 5^3 125series
  // 6 : 6^3 216series

  // niv 1 : 1^3+1*19 20xp  3diamonds
  // niv 2 : 2^3+2*19 46xp  6diamonds
  // niv 3 : 3^3+3*19 84xp  9diamonds
  // niv 4 : 4^3+4*19 140xp 12diamonds


  calcFromFirstRecord: (array:I_Record[]) => {
    let firstRecord = S_Record.getFirstRecord(array);
    if(!firstRecord)
      return;
    let firstRecordDate = S_Date.getDayReference(firstRecord.date);
    if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() firstRecordDate',firstRecordDate);

    // pour chaque jour, calcul
    let daysInMillis = S_Date.getDayReferencesBetween(firstRecordDate,(new Date()).getTime());
    // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() daysInMillis',daysInMillis); 

    let statusXP = 0;
    let statusDiamonds = 0;
    let statusTonus_item01 = 50;

    let seriesCountByType = {};
    for(var t = 0; t < S_Record.TYPES_2.length; t++){
      let type = S_Record.TYPES_2[t];
      seriesCountByType[type] = 0;
    }
    for(var t = 0; t < S_Record.TYPES_3.length; t++){
      let type = S_Record.TYPES_3[t];
      seriesCountByType[type] = 0;
    }


    for(var d = 0; d < daysInMillis.length; d++){
      let dayInMillis = daysInMillis[d];

      // on recupere tous les records de ce jour
      let recordsOfTheDay = S_App.getByFilters(array,[{attribute:'dayDate',value:dayInMillis}]);
      // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord()',dayInMillis,recordsOfTheDay);

      // on calcule les series de 5
      let series = S_Gaming.countSeries(recordsOfTheDay);

      // on calcule si une serie pour chaque visualisation / fitness
      let seriesOfVisualisation = S_Gaming.countSeries(S_Record.getRecordsOfType2(recordsOfTheDay));
      let seriesOfFitness = S_Gaming.countSeries(S_Record.getRecordsOfType3(recordsOfTheDay));
      let seriesTypesOfVisualisation = S_Gaming.countSeriesTypes_types2(S_Record.getRecordsOfType2(recordsOfTheDay));
      let seriesTypesOfFitness = S_Gaming.countSeriesTypes_types3(S_Record.getRecordsOfType3(recordsOfTheDay));
      if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord()',dayInMillis,'seriesOfVisualisation',seriesOfVisualisation,'seriesOfFitness',seriesOfFitness,'seriesTypesOfVisualisation',seriesTypesOfVisualisation,'seriesTypesOfFitness',seriesTypesOfFitness);

      let validateFirstSerieOfVisualisation = seriesOfVisualisation >= 1;
      let validateSerieForEachVisualisation = seriesTypesOfVisualisation >= 5;
      let validateFirstSerieOfFitness = seriesOfFitness >= 1;
      let validateSerieForEachFitness = seriesTypesOfFitness >= 5;

      // on tri par type
      let recordsOfTheDayByType = {};
      let typesOfTheDay = [];
      if(!!recordsOfTheDay && recordsOfTheDay.length>0){
        recordsOfTheDayByType = S_Record.groupByType(recordsOfTheDay);
        typesOfTheDay = Object.keys(recordsOfTheDayByType);
      }
      // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord()',dayInMillis,typesOfTheDay);
      for(var t = 0; t < S_Record.TYPES_2.length; t++){
        let type = S_Record.TYPES_2[t];
        let records = recordsOfTheDayByType[type];
        let seriesCount = records ? Math.floor(records.length / 5) : 0;
        if(seriesCount>0){
          seriesCountByType[type] += seriesCount;
        }
      }
      for(var t = 0; t < S_Record.TYPES_3.length; t++){
        let type = S_Record.TYPES_3[t];
        let records = recordsOfTheDayByType[type];
        let seriesCount = records ? Math.floor(records.length / 5) : 0;
        if(seriesCount>0){
          seriesCountByType[type] += seriesCount;
        }
      }
      // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord()',validateFirstSerieOfVisualisation,validateSerieForEachVisualisation,validateFirstSerieOfFitness,validateSerieForEachFitness);

      // XP
      // 1 par record + 2 par serie
      let xpOfDay = recordsOfTheDay.length + (series*2);

      // diamonds
      // 1 pour 1re serie visu + 3 pour 1re set visu complet
      // 2 pour 1re serie fitness + 6 pour 1re set fitness complet
      let diamondsOfDay = (validateFirstSerieOfVisualisation ? 1 : 0) + (validateSerieForEachVisualisation ? 3 : 0) + (validateFirstSerieOfFitness ? 2 : 0) + (validateSerieForEachFitness ? 6 : 0);

      // tonus
      // pour l'instant on up item01
      // il faudra valider pour chaque record si on up un autre item (ajout a l'item record)
      // 2 pour chaque serie
      let tonusOfTheDay_item01 = 0;
      if(series>0)
        tonusOfTheDay_item01 += series*2;

      if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord()',dayInMillis,'recordsOfTheDay',recordsOfTheDay,'xpOfDay',xpOfDay,'diamondsOfDay',diamondsOfDay,'tonusOfTheDay_item01',tonusOfTheDay_item01);

      statusXP += xpOfDay;
      statusDiamonds += diamondsOfDay;
      statusTonus_item01 = statusTonus_item01 - 10 > 0 ? statusTonus_item01 - 10 : 0;
      statusTonus_item01 += tonusOfTheDay_item01;
    }

    // steps
    if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord()','seriesCountByType',seriesCountByType);
    let maxCount_seriesOfOne = S_Gaming.countMaxOfAll(seriesCountByType);
    let minCount_seriesOfType2 = S_Gaming.countMin5OfTypes2(array,seriesCountByType);
    let minCount_seriesOfType3 = S_Gaming.countMin5OfTypes3(array,seriesCountByType);
    if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord()','max of any type',maxCount_seriesOfOne,'min of types 2',minCount_seriesOfType2,'min of types 3',minCount_seriesOfType3);

    let palier_anyType = 0;
    while(maxCount_seriesOfOne >= S_Gaming.getSeriesCountForStep(palier_anyType + 1)){
      // le palier palier+1 est valide
      let xpWon = S_Gaming.getXPForStep_anyType(palier_anyType+1);
      statusXP += xpWon;
      let diamondsWon = S_Gaming.getDiamondsForStep(palier_anyType+1);
      statusDiamonds += diamondsWon;
      palier_anyType++;
      if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() anyType','step',palier_anyType,'reached at',S_Gaming.getSeriesCountForStep(palier_anyType),'won',xpWon,diamondsWon,'next at',S_Gaming.getSeriesCountForStep(palier_anyType + 1),'to get xp',S_Gaming.getXPForStep_anyType(palier_anyType+1));
    }
    let palier_types2 = 0;
    while(minCount_seriesOfType2 >= S_Gaming.getSeriesCountForStep(palier_types2 + 1)){
      // le palier palier+1 est valide
      let xpWon = S_Gaming.getXPForStep_types2or3(palier_types2+1);
      statusXP += xpWon;
      let diamondsWon = S_Gaming.getDiamondsForStep(palier_anyType+1);
      statusDiamonds += diamondsWon;
      palier_types2++;
      if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() types2','step',palier_types2,'reached at',S_Gaming.getSeriesCountForStep(palier_types2),'won',xpWon,diamondsWon,'next at',S_Gaming.getSeriesCountForStep(palier_types2 + 1),'to get xp',S_Gaming.getXPForStep_anyType(palier_types2+1));
    }
    let palier_types3 = 0;
    while(minCount_seriesOfType3 >= S_Gaming.getSeriesCountForStep(palier_types3 + 1)){
      // le palier palier+1 est valide
      let xpWon = S_Gaming.getXPForStep_types2or3(palier_types3+1);
      statusXP += xpWon;
      let diamondsWon = S_Gaming.getDiamondsForStep(palier_anyType+1);
      statusDiamonds += diamondsWon;
      palier_types3++;
      if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() types3','step',palier_types3,'reached at',S_Gaming.getSeriesCountForStep(palier_types3),'won',xpWon,diamondsWon,'next at',S_Gaming.getSeriesCountForStep(palier_types3 + 1),'to get xp',S_Gaming.getXPForStep_anyType(palier_types3+1));
    }

    // niveau xp
    let level = 0;
    while(statusXP >= S_Gaming.getXPForLevel(level + 1)){
      // le niveau level+1 est valide
      let diamondsWon = S_Gaming.getDiamondsForLevel(level+1);
      statusDiamonds += diamondsWon;
      level++;
      if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() ','level',level,'reached at',S_Gaming.getXPForLevel(level),'won',diamondsWon,'next at',S_Gaming.getXPForLevel(level + 1));
    }
    


    S_Gaming.xp = statusXP;
    S_Gaming.diamonds = statusDiamonds;
    S_Gaming.tonus.item01.value = statusTonus_item01;
    S_Gaming.level = level;

    S_Gaming.seriesCountByType = seriesCountByType;
    S_Gaming.steps.maxCount_seriesOfOne = maxCount_seriesOfOne;
    S_Gaming.steps.minCount_seriesOfType2 = minCount_seriesOfType2;
    S_Gaming.steps.minCount_seriesOfType3 = minCount_seriesOfType3;
    S_Gaming.steps.level_anyType = palier_anyType;
    S_Gaming.steps.level_5ofType2 = palier_types2;
    S_Gaming.steps.level_5ofType3 = palier_types3;

    if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() xp',S_Gaming.xp,'diamonds',S_Gaming.diamonds,'tonus item01',S_Gaming.tonus.item01.value);
    if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() steps status',S_Gaming.steps);
    if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- calcFromFirstRecord() level',S_Gaming.level);
  },

  countSeries(recordsOfTheDay:I_Record[]):number{

    // on tri par type
    let recordsOfTheDayByType = {};
    let typesOfTheDay = [];
    if(!!recordsOfTheDay && recordsOfTheDay.length>0){
      recordsOfTheDayByType = S_Record.groupByType(recordsOfTheDay);
      typesOfTheDay = Object.keys(recordsOfTheDayByType);
    }
    // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- countSeries()',typesOfTheDay);

    // on calcule les series de 5
    let seriesCount = 0;
    if(!!typesOfTheDay && typesOfTheDay.length>0){
      for(var t = 0; t < typesOfTheDay.length; t++){
        let type = typesOfTheDay[t];
        let records = recordsOfTheDayByType[type];
        let seriesCountOfType = records ? Math.floor(records.length / 5) : 0;
        // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- countSeries()',type,records,seriesCountOfType);
        seriesCount += seriesCountOfType;
      }
    }
    return seriesCount;
  },
  countSeriesTypes_types2(recordsOfTheDay:I_Record[]):number{
    let typesCount = 0;

    // on tri par type
    let recordsOfTheDayByType = {};
    let typesOfTheDay = [];
    if(!!recordsOfTheDay && recordsOfTheDay.length>0){
      recordsOfTheDayByType = S_Record.groupByType(recordsOfTheDay);
      typesOfTheDay = Object.keys(recordsOfTheDayByType);
    }
    // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- countSeriesTypes()',typesOfTheDay);

    for(var t = 0; t < S_Record.TYPES_2.length; t++){
      let type = S_Record.TYPES_2[t];
      let records = recordsOfTheDayByType[type];
      let seriesCount = records ? Math.floor(records.length / 5) : 0;
      if(seriesCount>0)
        typesCount++;
    }
    return typesCount;
  },
  countSeriesTypes_types3(recordsOfTheDay:I_Record[]):number{
    let typesCount = 0;

    // on tri par type
    let recordsOfTheDayByType = {};
    let typesOfTheDay = [];
    if(!!recordsOfTheDay && recordsOfTheDay.length>0){
      recordsOfTheDayByType = S_Record.groupByType(recordsOfTheDay);
      typesOfTheDay = Object.keys(recordsOfTheDayByType);
    }
    // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- countSeriesTypes()',typesOfTheDay);

    for(var t = 0; t < S_Record.TYPES_3.length; t++){
      let type = S_Record.TYPES_3[t];
      let records = recordsOfTheDayByType[type];
      let seriesCount = records ? Math.floor(records.length / 5) : 0;
      if(seriesCount>0)
        typesCount++;
    }
    return typesCount;
  },
  // le nombre max de series d'un type, tout type confondu
  countMaxOfAll(seriesCountByType){
    let max = 0;
    for(var t = 0; t < S_Record.TYPES_2.length; t++){
      let type = S_Record.TYPES_2[t];
      if(seriesCountByType[type] > max) max = seriesCountByType[type];
    }
    for(var t = 0; t < S_Record.TYPES_3.length; t++){
      let type = S_Record.TYPES_3[t];
      if(seriesCountByType[type] > max) max = seriesCountByType[type];
    }
    return max;
  },
  // le nombre min de series sur min 5 types
  countMin5OfTypes2(records:I_Record[],seriesCountByType){
    let typesCount = S_Gaming.countSeriesTypes_types2(records);
    if(typesCount < 5)  // on a jamais fait 1 serie sur 5 type
      return 0;

    let min = 0;
    for(var t = 0; t < S_Record.TYPES_2.length; t++){
      let type = S_Record.TYPES_2[t];
      // if(S_Gaming.isLogDebug) console.log('-- S_Gaming.tsx -- countMin5OfTypes2() ',type,seriesCountByType[type],min);
      if(seriesCountByType[type]>0){
        if(min === 0){
          min = seriesCountByType[type];
        }else if(seriesCountByType[type] < min){
          min = seriesCountByType[type];
        }
      }
    }
    return min;
  },
  countMin5OfTypes3(records:I_Record[],seriesCountByType){
    let typesCount = S_Gaming.countSeriesTypes_types3(records);
    if(typesCount < 5)  // on a jamais fait 1 serie sur 5 type
      return 0;

    let min = 0;
    for(var t = 0; t < S_Record.TYPES_3.length; t++){
      let type = S_Record.TYPES_3[t];
      if(seriesCountByType[type]>0){
        if(min === 0){
          min = seriesCountByType[type];
        }else if(seriesCountByType[type] < min){
          min = seriesCountByType[type];
        }
      }
    }
    return min;
  },

  // nombre de series a faire pour finir un palier
  getSeriesCountForStep(step:number):number{
    return Math.pow(step,3);
  },
  // nombre d'xp gagnes lors de la validation d'un palier
  getXPForStep_anyType(step:number):number{
    return Math.pow(step,3) * 3;
  },
  // nombre d'xp gagnes lors de la validation d'un palier
  getXPForStep_types2or3(step:number):number{
    return Math.pow(step,3) * 6;
  },
  // nombre de diamonds gagnes lors de la validation d'un palier
  getDiamondsForStep(step:number):number{
    return step;
  },
  // nombre d'xp a avoir pour le niveau
  getXPForLevel(step:number):number{
    return Math.pow(step,3) + (step*19);
  },
  // nombre de diamonds gagnes lors de la validation d'un niveau
  getDiamondsForLevel(step:number):number{
    return step*3;
  }


};
export default S_Gaming;