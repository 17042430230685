// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';

// Composants génériques
// import S_App      from '../../services/S_App';

// Composants propres
// import S_Record    from '../services/S_Record';
import {I_Record}  from '../services/I_Record';
import Calendar           from './Calendar';

// Styles
// import './PanelCalendar.scss';

type PanelCalendarProps = {
  records: I_Record[];
  style?: any;
}

function PanelCalendar(props:PanelCalendarProps) {

  // props
  let {records,style} = props;

  // state
  const [category, setCategory] = useState(2);

  

  // autres variables



  return (
    <Container fixed className="panel-contents">

      <div style={style}>
        <div className="panel-contents-header">
          <Container>
            <Grid container className="mv-05">
              <Grid item xs={12} className="text-center">
                <ButtonGroup aria-label="Catégorie">
                  <Button variant={category === 1 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setCategory(1)} size="small" title="PTAE">PTAE</Button>
                  <Button variant={category === 2 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setCategory(2)} size="small" title="Visualisations">Visualisations</Button>
                  <Button variant={category === 3 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setCategory(3)} size="small" title="Fitness">Fitness</Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Container>
        </div>
        
        <div className="mt-4">
          <Calendar records={records} category={category} />
        </div>
      </div>


    </Container>
  );
}

export default PanelCalendar;
