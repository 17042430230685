// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import FlareIcon from '@material-ui/icons/Flare';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record           from '../services/S_Record';
// import {I_Record}         from '../services/I_Record';

// Styles
// import './Xp.scss';

type XpBarProps = {
  value: number;
  theme?: string;  // bar, number
  progress?:number;
  className?: string;
}

function XpBar(props:XpBarProps) {

  // props
  let {value,theme,progress,className} = props;

  if(theme === 'bar')
    return <div className="xp-status bar"><span>{value}</span> <FlareIcon /></div>;

  if(theme === 'levelbar')
    return (
      <div className={'xp-status levelbar ' + (className ? className : '')}>
        <span className="progress" style={{width:progress+'%'}}></span>
        <span className="value"><span>{progress}%</span></span>
      </div>
    );

  return <div className="xp-status"><span>{value}</span> <FlareIcon /></div>;
}

export default XpBar;
