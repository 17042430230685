let S_App = {
  STORAGE_KEY_PREFIX : 'perinee',
  isLogDebug: process.env.REACT_APP_ENV !== 'production',

  getByID: (array,id:number|string) => {
    if(id===undefined){
      // console.log('   id undefined',array,id);
      return;
    }
    if(!array || array.length===0){
      // console.log('   array empty',array,id);
      return;
    }
    for(var i = 0; i < array.length; i++){
      if(typeof id === 'number'){
        // console.log('   id is number',array,id);
        if(array[i].id !== id)
          continue;
        return array[i];
      }else if(typeof id === 'string'){
        // console.log('   id is string',array,id);
        if(array[i].id !== parseInt(id))
          continue;
        return array[i];
      }
    }
    return;
  },

  getIndexOfByID(array,id:number){
    if(id===undefined)
      return;
    let foundIndex = -1;
    for(var i = 0; i < array.length; i++){
      if(array[i].id !== id)
        continue;
      foundIndex = i;
      break;
    }
    return foundIndex;
  },

  // filters : [{attribute:xxxx,value:xxx}]
  getByFilters: (array,filters) => {
    if(!array)
      return;
    if(array.length===0)
      return array;
    // if(S_App.isLogDebug) console.log('getByFilters() items',array,'filters',filters);
    if(!filters || filters.length===0)
      return array;
    let filtered = [];
    for(var i = 0; i < array.length; i++){
      let item = array[i];
      // console.log('getByFilters - ',item);
      let validateAllFilters = true;
      for(var f = 0;f < filters.length; f++){
        let filter = filters[f];
        // console.log('getByFilters - ',filter,filter.attribute,filter.value);
        if(filter.value === false){
          if(!item[filter.attribute] || item[filter.attribute] === false)
            continue;
          validateAllFilters = false;
          break;
        }else{
          if(item[filter.attribute] === filter.value)
            continue;
          validateAllFilters = false;
          break;
        }
      }
      if(!validateAllFilters)
        continue;
      filtered.push(item);
    }
    // if(S_App.isLogDebug) console.log('getByFilters() items',filtered);
    return filtered;
  },



  replaceBr(text:string){
    if(!text)
      return undefined;
    let newText = text.replace('\n\n','<br/><br/>').replace('\n','<br/>');
    newText = newText.replace(/(<? *script)/gi, 'illegalscript');
    return newText;
  },
  sortByOrder(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let orderA = a.order !== undefined ? a.order : a.id;
      let orderB = b.order !== undefined ? b.order : b.id;
      return orderB > orderA ? -1 : 1;
    });
    return arrayOrdered;
  },
  sortByDate(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let orderA = a.date !== undefined ? a.date : a.id;
      let orderB = b.date !== undefined ? b.date : b.id;
      return orderB > orderA ? -1 : 1;
    });
    return arrayOrdered;
  },
  groupByKey(array, key) {
   return array.reduce((hash, obj) => {
       if(obj===undefined || obj[key] === undefined) return hash; 
       return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
     }, {})
  },


  // stockage
  checkLocalStorageSize(cb){
    if (window.localStorage && !window.localStorage.getItem('size')) {
      var i = 0;
      try {
        // Test up to 10 MB
        for (i = 250; i <= 10000; i += 250) {
          localStorage.setItem('test', new Array((i * 1024) + 1).join('a'));
        }
      } catch (e) {
        var approximateSize = i - 250;
        localStorage.removeItem('test');
        localStorage.setItem('size', approximateSize + '');
        console.log('Size of localStorage :',approximateSize,'Kb');         
      }
    }
    if(cb)
      cb();
  },
  readLocalStorageSizeEffective(arrayOfItems:string[],cb){
    var total = 0;
    if (window.localStorage){
      for(var i = 0; i < arrayOfItems.length; i++){
        if(!window.localStorage.getItem(S_App.STORAGE_KEY_PREFIX + '_' + arrayOfItems[i]))
          continue;
        var value = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + arrayOfItems[i]];
        var valueKb = encodeURI(value).split(/%..|./).length - 1;
        total += valueKb;
      }
      console.log('Size of datas :',total,'b');
    }
    if(cb)
      cb(total);
  },
  // renvoie en Kb la taille max de stockage possible
  readLocalStorageSize: (cb) => {
    var size = window.localStorage['size'];      
    if(S_App.isLogDebug) console.log('-- S_App.tsx -- readLocalStorageSize() size',size);
    if(cb)
      cb(size);
  },
  getLocalStorageDatasStringified(arrayOfItems:string[],cb){
    var all = '';
    if (window.localStorage){
      for(var i = 0; i < arrayOfItems.length; i++){
        var KEY = S_App.STORAGE_KEY_PREFIX + '_' + arrayOfItems[i];
        if(!window.localStorage.getItem(KEY))
          continue;
        var value = window.localStorage[KEY];
        all = all + (all===''?'':'\n') + KEY + '=' + value;
      }
      console.log('All datas :',all);
    }
    if(cb)
      cb(all);
  }
    
  
};
export default S_App;