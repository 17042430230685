import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record         from '../services/S_Record';
// import {I_Record}        from '../services/I_Record';
import XpBar      from '../elements/XpBar';

// Styles
// import './Dialog.scss';

type DialogDetailsTonusProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  tonus: any;
}

function DialogDetailsTonus(props:DialogDetailsTonusProps) {

  // props
  let {isDialogOpened,closeDialog,tonus} = props;

  // state

  // autres

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="text-center capitalize">Tonus</div>
        <div className="text-center capitalize">
          <div>{tonus && tonus.item01 ? tonus.item01.name : ''}</div>
          <div>{tonus && tonus.item01 ? tonus.item01.value : ''}%</div>
        </div>
      </DialogTitle>
      <DialogContent>

        <div>
          <p>Le tonus représente l'état de santé de votre périnée. Il est représenté par la vigueur d'une plante. Plus vous faites d'efforts au quotidien, plus votre périnée est en forme. Si vous arrétez vos efforts, votre muscle se relâche.</p>
          
          <h4>Vigueur continue</h4>
          <p>Chaque nouvelle journée, la plante perd 10% de sa vigueur.</p>
          <p>Chaque série de 5 exercices du même type réalisés sur un même jour lui redonne +2% de vigueur.</p>

          <p>Pour maintenir un tonus constant chaque jour, il faut donc réaliser 5 séries de 5 exercices du même type tous les jours !</p>
          <p>Pour monter le tonus de 10% par jour, il faut réaliser 10 séries de 5 exercices du même type par jour.</p>
        </div>
      

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogDetailsTonus;
