import React, { useState, useEffect } from 'react';

// Composants Material UI
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// Composants generiques
import S_App        from './services/S_App';
import S_Date       from './services/S_Date';
// import Panels       from './components/Panels';
import { Panels } from 'react-mui-pwa-tools';

// Composants propres
import S_Gaming          from './metier/services/S_Gaming';
import S_Record          from './metier/services/S_Record';
import {I_Record}        from './metier/services/I_Record';
import PanelConfig        from './metier/components/PanelConfig';
import PanelCalendar      from './metier/components/PanelCalendar';
import ListTypes      from './metier/components/ListTypes';
import DialogAddRecord    from './metier/dialogs/DialogAddRecord';
import DialogEditRecord   from './metier/dialogs/DialogEditRecord';
import DialogDetailsCategory1    from './metier/dialogs/DialogDetailsCategory1';
import DialogDetailsCategory2    from './metier/dialogs/DialogDetailsCategory2';
import DialogDetailsCategory3    from './metier/dialogs/DialogDetailsCategory3';
import DialogDetailsXP           from './metier/dialogs/DialogDetailsXP';
import DialogDetailsDiamond      from './metier/dialogs/DialogDetailsDiamond';
import DialogDetailsTonus        from './metier/dialogs/DialogDetailsTonus';
import XpBar                     from './metier/elements/XpBar';
import DiamondBar                from './metier/elements/DiamondBar';
import GameAvatar                from './metier/elements/GameAvatar';
import GameStatusBar             from './metier/elements/GameStatusBar';
import Daily                     from './metier/elements/Daily';
import TonusProgress             from './metier/elements/TonusProgress';

// Styles
import './App.scss';

function App() {

  // state
  const [swipePanelAllowed, setSwipePanelAllowed] = useState(true);
  const [records, setRecords] = useState([]);
  const [isDialogAddRecordOpened, setIsDialogAddRecordOpened] = useState(false);
  const [isDialogEditRecordOpened, setIsDialogEditRecordOpened] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState(undefined);
  const [dialogType, setDialogType] = useState(undefined);
  const [dialogTypePrefImg, setDialogTypePrefImg] = useState(undefined);

  const [isDialogDetailsCategory1Opened, setIsDialogDetailsCategory1Opened] = useState(false);
  const [isDialogDetailsCategory2Opened, setIsDialogDetailsCategory2Opened] = useState(false);
  const [isDialogDetailsCategory3Opened, setIsDialogDetailsCategory3Opened] = useState(false);
  const [isDialogDetailsXPOpened, setIsDialogDetailsXPOpened] = useState(false);
  const [isDialogDetailsDiamondOpened, setIsDialogDetailsDiamondOpened] = useState(false);
  const [isDialogDetailsTonusOpened, setIsDialogDetailsTonusOpened] = useState(false);

  const [xp, setXP] = useState(0);
  const [diamond, setDiamond] = useState(0);
  const [tonus, setTonus] = useState(undefined);
  const [steps, setSteps] = useState(undefined);
  const [level, setLevel] = useState(0);

  // chargement des donnees
  useEffect(() => {
    S_Record.fetch(() => {
      setRecords([...S_Record.records]);
      console.log('records',S_Record.records);
      S_Gaming.calcFromFirstRecord(S_Record.records);
      setXP(S_Gaming.xp);
      setDiamond(S_Gaming.diamonds);
      setTonus(S_Gaming.tonus);
      setSteps(S_Gaming.steps);
      setLevel(S_Gaming.level);
    });
    if(!!dialogType){
      S_Record.fetchPrefImage(dialogType,(value) => {
        setDialogTypePrefImg(value);
      });
    }
  }, []);




  function displayDialogAddRecord(type){
    setDialogType(type);
    S_Record.fetchPrefImage(type,(value) => {
      setDialogTypePrefImg(value);
    });
    setIsDialogAddRecordOpened(true);
  }
  function closeDialogAddRecord(){
    setDialogType(undefined);
    setIsDialogAddRecordOpened(false);
  }
  function addrecordValidate(type){
    S_Record.add((new Date()).getTime(),type,() => {
      setRecords([...S_Record.records]);
      S_Gaming.calcFromFirstRecord(S_Record.records);
      setXP(S_Gaming.xp);
      setDiamond(S_Gaming.diamonds);
      setTonus(S_Gaming.tonus);
      setSteps(S_Gaming.steps);
      setLevel(S_Gaming.level);
    });
  }
  function editRecord(record:I_Record){
    setRecordToEdit(record);
    setIsDialogEditRecordOpened(true);
  }
  function editRecordValidate(dateInMillis:number){
    S_Record.update(dateInMillis,'date',recordToEdit,() => {
      setRecords([...S_Record.records]);
      setIsDialogEditRecordOpened(false);
      setRecordToEdit(undefined);
    });
  }
  function editRecordDelete(){
    S_Record.remove(recordToEdit,() => {
      setRecords([...S_Record.records]);
      setIsDialogEditRecordOpened(false);
      setRecordToEdit(undefined);
    });
  }
  function closeeditRecord(){
    setIsDialogEditRecordOpened(false);
    setRecordToEdit(undefined);
  }


  function openHelp(category:number){
    if(category===1){
      setIsDialogDetailsCategory1Opened(true);
      setIsDialogDetailsCategory2Opened(false);
      setIsDialogDetailsCategory3Opened(false);
      setIsDialogDetailsXPOpened(false);
      setIsDialogDetailsDiamondOpened(false);
      setIsDialogDetailsTonusOpened(false);
    }
    if(category===2){      
      setIsDialogDetailsCategory1Opened(false);
      setIsDialogDetailsCategory2Opened(true);
      setIsDialogDetailsCategory3Opened(false);
      setIsDialogDetailsXPOpened(false);
      setIsDialogDetailsDiamondOpened(false);
      setIsDialogDetailsTonusOpened(false);
    }
    if(category===3){      
      setIsDialogDetailsCategory1Opened(false);
      setIsDialogDetailsCategory2Opened(false);
      setIsDialogDetailsCategory3Opened(true);
      setIsDialogDetailsXPOpened(false);
      setIsDialogDetailsDiamondOpened(false);
      setIsDialogDetailsTonusOpened(false);
    }
    if(category===4){      
      setIsDialogDetailsCategory1Opened(false);
      setIsDialogDetailsCategory2Opened(false);
      setIsDialogDetailsCategory3Opened(false);
      setIsDialogDetailsXPOpened(true);
      setIsDialogDetailsDiamondOpened(false);
      setIsDialogDetailsTonusOpened(false);
    }
    if(category===5){      
      setIsDialogDetailsCategory1Opened(false);
      setIsDialogDetailsCategory2Opened(false);
      setIsDialogDetailsCategory3Opened(false);
      setIsDialogDetailsXPOpened(false);
      setIsDialogDetailsDiamondOpened(true);
      setIsDialogDetailsTonusOpened(false);
    }
    if(category===6){      
      setIsDialogDetailsCategory1Opened(false);
      setIsDialogDetailsCategory2Opened(false);
      setIsDialogDetailsCategory3Opened(false);
      setIsDialogDetailsXPOpened(false);
      setIsDialogDetailsDiamondOpened(false);
      setIsDialogDetailsTonusOpened(true);
    }
  }
  function closeHelp(){
    setIsDialogDetailsCategory1Opened(false);
    setIsDialogDetailsCategory2Opened(false);
    setIsDialogDetailsCategory3Opened(false);
    setIsDialogDetailsXPOpened(false);
    setIsDialogDetailsDiamondOpened(false);
    setIsDialogDetailsTonusOpened(false);
  }

  // ----------------------------

  let steps_seriesOfOne = steps ? Array.from({length: steps.level_anyType}, (_, i) => i + 1) : [];
  steps_seriesOfOne = steps_seriesOfOne.reverse();
  let steps_seriesOfType2 = steps ? Array.from({length: steps.level_5ofType2}, (_, i) => i + 1) : [];
  steps_seriesOfType2 = steps_seriesOfType2.reverse();
  let steps_seriesOfType3 = steps ? Array.from({length: steps.level_5ofType3}, (_, i) => i + 1) : [];
  steps_seriesOfType3 = steps_seriesOfType3.reverse();

  let currentDayRefInMillis = S_Date.getCurrentDayReference();
  let currentDayRecords = S_App.getByFilters(records,[{attribute:'dayDate',value:currentDayRefInMillis}]);
  let currentDayRecordsOfType2 = S_Record.getRecordsOfType2(currentDayRecords);
  let currentDayRecordsOfType3 = S_Record.getRecordsOfType3(currentDayRecords);

  let levels = Array.from({length: level}, (_, i) => i + 1);
  levels = levels.reverse();
  let levelProgress = xp - S_Gaming.getXPForLevel(level);
  let levelProgressMax = S_Gaming.getXPForLevel(level+1) - S_Gaming.getXPForLevel(level);
  let levelProgressPercent = Math.floor(levelProgress * 100 / levelProgressMax);

  let topElementMargin = {marginTop: '86px'};

  let panelContents = [
    <div className="relative">
      <div className="p-2" style={topElementMargin}>
        <ListTypes 
          onClick={displayDialogAddRecord}
          onClickHelp={openHelp}/>
      </div>
    </div>,
    <PanelCalendar records={records}/>,
    <div>3</div>,
    <div className="relative">
      
      <div style={topElementMargin} className="text-center">
        <h3>Campagnes</h3>
        {steps ? (
        <div className="text-left">
          <Daily 
            completed={false} 
            stepNumber={steps.level_anyType+1} 
            title={'Réaliser '+S_Gaming.getSeriesCountForStep(steps.level_anyType+1)+' série(s) d\'un même exercice'} 
            xp={S_Gaming.getXPForStep_anyType(steps.level_anyType+1)} 
            diamonds={S_Gaming.getDiamondsForStep(steps.level_anyType+1)} 
            progress={steps.maxCount_seriesOfOne} 
            progressmax={S_Gaming.getSeriesCountForStep(steps.level_anyType+1)}/>
          <Daily 
            completed={false} 
            stepNumber={steps.level_5ofType2+1} 
            title={'Réaliser '+S_Gaming.getSeriesCountForStep(steps.level_5ofType2+1)+' série(s) sur 5 visualisations'} 
            xp={S_Gaming.getXPForStep_types2or3(steps.level_5ofType2+1)} 
            diamonds={S_Gaming.getDiamondsForStep(steps.level_5ofType2+1)} 
            progress={steps.minCount_seriesOfType2} 
            progressmax={S_Gaming.getSeriesCountForStep(steps.level_5ofType2+1)}/>
          <Daily 
            completed={false} 
            stepNumber={steps.level_5ofType3+1} 
            title={'Réaliser '+S_Gaming.getSeriesCountForStep(steps.level_5ofType3+1)+' série(s) sur 5 fitness'} 
            xp={S_Gaming.getXPForStep_types2or3(steps.level_5ofType3+1)} 
            diamonds={S_Gaming.getDiamondsForStep(steps.level_5ofType3+1)} 
            progress={steps.minCount_seriesOfType3} 
            progressmax={S_Gaming.getSeriesCountForStep(steps.level_5ofType3+1)}/>

          <br/>

          {steps_seriesOfOne.map((stepNumber,index) => (
            <Daily key={index} completed={steps.level_anyType>=stepNumber} stepNumber={stepNumber} title={'Réaliser '+S_Gaming.getSeriesCountForStep(stepNumber)+' série(s) d\'un même exercice'} xp={S_Gaming.getXPForStep_anyType(stepNumber)} diamonds={S_Gaming.getDiamondsForStep(stepNumber)} />
          ))}
          {steps_seriesOfType2.map((stepNumber,index) => (
            <Daily key={index} completed={steps.level_5ofType2>=stepNumber} stepNumber={stepNumber} title={'Réaliser '+S_Gaming.getSeriesCountForStep(stepNumber)+' série(s) sur 5 visualisations'} xp={S_Gaming.getXPForStep_types2or3(stepNumber)} diamonds={S_Gaming.getDiamondsForStep(stepNumber)} />
          ))}
          {steps_seriesOfType3.map((stepNumber,index) => (
            <Daily key={index} completed={steps.level_5ofType3>=stepNumber} stepNumber={stepNumber} title={'Réaliser '+S_Gaming.getSeriesCountForStep(stepNumber)+' série(s) sur 5 fitness'} xp={S_Gaming.getXPForStep_types2or3(stepNumber)} diamonds={S_Gaming.getDiamondsForStep(stepNumber)}/>
          ))}
        </div>
        ):null}
        <h3>Défis quotidiens</h3>
        <div className="text-left">
          <Daily 
            completed={S_Gaming.countSeries(currentDayRecordsOfType2) > 0} 
            title={'Réaliser une série en visualisation'} 
            diamonds={1}/>
          <Daily 
            completed={S_Gaming.countSeries(currentDayRecordsOfType3) > 0} 
            title={'Réaliser une série en fitness'} 
            diamonds={2}/>
          <Daily 
            completed={S_Gaming.countSeriesTypes_types2(currentDayRecordsOfType2) > 4} 
            title={'Réaliser une série sur 5 visualisations'} 
            diamonds={3} 
            progress={S_Gaming.countSeriesTypes_types2(currentDayRecordsOfType2)} 
            progressmax={5}/>
          <Daily 
            completed={S_Gaming.countSeriesTypes_types2(currentDayRecordsOfType3) > 4} 
            title={'Réaliser une série sur 5 fitness'} 
            diamonds={6} 
            progress={S_Gaming.countSeriesTypes_types3(currentDayRecordsOfType3)} 
            progressmax={5}/>
        </div>
        <h3>Niveaux</h3>
        <div className="text-left">
          <Daily 
            completed={false} 
            title={'Niveau '+(level+1)} 
            goal={<XpBar value={levelProgressMax} />}
            diamonds={S_Gaming.getDiamondsForLevel(level+1)} 
            progress={levelProgress} 
            progressmax={levelProgressMax}/>
          {levels.map((levelO,index) => (
            <Daily key={index} 
              completed={level>=levelO} 
              title={'Niveau '+levelO} 
              goal={<XpBar value={S_Gaming.getXPForLevel(levelO) - S_Gaming.getXPForLevel(levelO-1)} />}
              diamonds={S_Gaming.getDiamondsForLevel(levelO)} />
          ))}
        </div>
      </div>
    </div>,
    <PanelConfig style={topElementMargin} />
  ];

  let tabs = [
    {
      label: 'Bonjour',
      icon: <PlayCircleOutlineIcon /> 
    },
    {
      label: 'Chronologie',
      icon: <CalendarTodayIcon /> 
    },
    {
      label: 'Aujourd\'hui',
      icon: <CalendarTodayIcon /> 
    },
    {
      label: 'Gaming',
      icon: <DashboardIcon /> 
    },
    {
      label: 'Config',
      icon: <MoreHorizOutlinedIcon /> 
    }
  ]; 

  let topContent = (
    <div className="panel-navigation-topelement text-center">
      <div className={'abs-tl m-05 background'}>
        <GameAvatar 
          xp={levelProgress} 
          level={level} 
          levelprogress={levelProgressPercent} 
          className={''}
          onClick={(e) => openHelp(4)} />
        <TonusProgress 
          percent={tonus && tonus.item01 ? tonus.item01.value : 0} 
          name={tonus && tonus.item01 ? tonus.item01.name : ''} 
          className={''}
          onClick={(e) => openHelp(6)} />
      </div>
      <div className={'abs-tr m-05 background'}>
        <GameStatusBar 
          className={''} 
          diamonds={diamond}
          onClick={(e) => openHelp(5)} />
      </div>
    </div>
  );

  return (
    <>
    <Panels 
      enabled={swipePanelAllowed}
      contents={panelContents}
      tabs={tabs}
      defaultPanelIndex={0}
      topContent={topContent}
      size="small"/>
    <DialogDetailsCategory1
      isDialogOpened={isDialogDetailsCategory1Opened}
      closeDialog={closeHelp} />
    <DialogDetailsCategory2
      isDialogOpened={isDialogDetailsCategory2Opened}
      closeDialog={closeHelp} />
    <DialogDetailsCategory3
      isDialogOpened={isDialogDetailsCategory3Opened}
      closeDialog={closeHelp} />
    <DialogDetailsXP
      isDialogOpened={isDialogDetailsXPOpened}
      closeDialog={closeHelp}
      xp={xp}
      level={level}
      steps={steps} />
    <DialogDetailsDiamond
      isDialogOpened={isDialogDetailsDiamondOpened}
      closeDialog={closeHelp}
      diamonds={diamond} />
    <DialogDetailsTonus
      isDialogOpened={isDialogDetailsTonusOpened}
      closeDialog={closeHelp}
      tonus={tonus} />
    <DialogAddRecord
      isDialogOpened={isDialogAddRecordOpened}
      closeDialog={closeDialogAddRecord}
      onClickValidate={addrecordValidate}
      type={dialogType}
      prefImg={dialogTypePrefImg}
      onClickEdit={editRecord}
      records={records} />
    {recordToEdit ? (
    <DialogEditRecord
      isDialogOpened={isDialogEditRecordOpened}
      closeDialog={closeeditRecord}
      onClickDelete={editRecordDelete}
      onClickValidate={editRecordValidate}
      item={recordToEdit} />
    ):null}
    </>
  );
}

export default App;
