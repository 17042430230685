import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// Composants génériques
// import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record         from '../services/S_Record';
import {I_Record}        from '../services/I_Record';

// Styles
// import './Dialog.scss';

type DialogEditRecordProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickDelete: any;
  onClickValidate: any;
  item: I_Record;
}

function DialogEditRecord(props:DialogEditRecordProps) {

  // props
  let {isDialogOpened,closeDialog,onClickDelete,onClickValidate,item} = props;

  // console.log('item',item);

  let dateMillis = item ? item.date : undefined;

  // state
  const [dayForMaterial, setDayForMaterial] = useState(S_Date.formatDateForMaterial(dateMillis));
  const [hourForMaterial, setHourForMaterial] = useState(S_Date.formatTimeForMaterial(dateMillis));

  function changeDay(value){
    setDayForMaterial(value);
  }
  function changeHour(value){
    setHourForMaterial(value);
  }

  console.log(dayForMaterial,hourForMaterial);

  // on transforme la date "material" en date in millis
  let dateSelectedInMillis = S_Date.formatDateInMillis2(dayForMaterial,hourForMaterial);
  console.log((new Date(dateSelectedInMillis)));

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Modifier un pompage</DialogTitle>
      <DialogContent>

        <TextField label="Date" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
          value={dayForMaterial} 
          onChange={(e) => changeDay(e.target.value)} />
        <TextField label="Heure" type="time" size="small" InputLabelProps={{ shrink: true }} fullWidth 
          value={hourForMaterial} 
          onChange={(e) => changeHour(e.target.value)} />

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        <Button onClick={(e) => onClickDelete()} color="primary">Supprimer</Button>
        <Button onClick={(e) => onClickValidate(dateSelectedInMillis)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogEditRecord;
