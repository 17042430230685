import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record         from '../services/S_Record';
// import {I_Record}        from '../services/I_Record';

// Styles
// import './Dialog.scss';

type DialogDetailsCategory2Props = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close
}

function DialogDetailsCategory2(props:DialogDetailsCategory2Props) {

  // props
  let {isDialogOpened,closeDialog} = props;

  // state

  // autres

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="text-center capitalize">Visualisations</div>
      </DialogTitle>
      <DialogContent>

        <p>
          Visualiser l'image sur l'INSPIRATION spontanée. Visualiser/Imaginer le mouvement sur l'EXPIRATION douce et prolongée (non forcée). 
          <br/>/!\ Prendre le temps, travailler avec une image qui « parle » = trouver la « sienne »
        </p>
        <p>Travailler par série de 4-5 fois, calmement, sans force, sans contracter/serrer ni les abdominaux, ni les fessiers ! Varier ensuite assis/debout etc...</p>
      

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogDetailsCategory2;
