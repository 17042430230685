// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import FilterVintageIcon from '@material-ui/icons/FilterVintage';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record           from '../services/S_Record';
// import {I_Record}         from '../services/I_Record';

// Styles
import './TonusProgress.scss';

type TonusProgressProps = {
  percent: number;
  name: string;

  className?: string;
  onClick?: any;
}

function TonusProgress(props:TonusProgressProps) {

  // props
  let {percent,name,className,onClick} = props;

  return (
    <div className={'text-center tonusProgress '+className} onClick={onClick}>
      <div className="tonusProgressPercent" style={{height:percent+'%'}}></div>
      <div className="item">
        <div className="icon"><FilterVintageIcon /></div>
        <div className="name">{name}</div>
        <div className="value">{percent}%</div>
      </div>
    </div>
  );
}

export default TonusProgress;
