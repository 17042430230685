// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Composants génériques
import S_App      from '../../services/S_App';
// import Version    from '../../elements/Version';
// import Credits    from '../../elements/Credits';
// import Saves      from '../../components/Saves';
import { Saves } from 'react-mui-pwa-tools';
import { Version } from 'react-mui-pwa-tools';
import { Credits } from 'react-mui-pwa-tools';

// Composants propres
import S_Record         from '../services/S_Record';

// Styles
// import './PanelConfig.scss';

import pkg from '../../../package.json';

type PanelConfigProps = {
  style?: any;
}

function PanelConfig(props:PanelConfigProps) {

  // props
  let {style} = props;

  // state

  // chargement des donnees
  
  // autres variables
  const isDEV = !!process.env && process.env.NODE_ENV === 'development';


  return (
    <Container fixed className="panel-contents">

      <div style={style}>
        <Saves 
          storageKeyPrefix={S_App.STORAGE_KEY_PREFIX}
          storageKeys={[S_Record.STORAGE_KEY_RECORDS]}
          shareTitle="Données périnée"
          className="" />

        <Version
          development={isDEV}
          version={pkg.version}
          lastUpdate={pkg.lastUpdate} />

        <Credits>
          Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
          <br/>Icons made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          <br/>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          <br/>Icons made by <a href="https://www.flaticon.com/authors/kiranshastry" title="Kiranshastry">Kiranshastry</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          <br/>Icons made by <a href="https://www.flaticon.com/authors/itim2101" title="itim2101">itim2101</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
        </Credits>
      </div>

      
    </Container>
  );
}

export default PanelConfig;
