import S_App    from   './S_App';

const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

let S_Date = {
  isLogDebug: false, //S_App.isLogDebug,  

  groupByDate(array){
    let byDate = [];
    try{
      if(S_Date.isLogDebug) console.log('groupByDate() array',array);

      let arraySorted = S_App.sortByDate(array).reverse();

      byDate = S_App.groupByKey(arraySorted, 'date');
      if(S_Date.isLogDebug) console.log('groupByDate() grouped by date',byDate);
    }catch(err){
      console.error(err);
    }
    return byDate;
  },
  groupByDayDate(array){
    let byDate = [];
    try{
      if(S_Date.isLogDebug) console.log('groupByDate() array',array);

      let arraySorted = S_App.sortByDate(array).reverse();
      for(var i = 0; i < arraySorted.length; i++){
        let item = arraySorted[i];
        if(item!==undefined){
          item.dayDate = S_Date.getDayReference(item.date);
          item.yearDate = (new Date(item.date)).getFullYear();
          item.monthDate = (new Date(item.date)).getMonth();
        }
      }

      byDate = S_App.groupByKey(arraySorted, 'dayDate');
      if(S_Date.isLogDebug) console.log('groupByDate() grouped by dayDate',byDate);
    }catch(err){
      console.error(err);
    }
    return byDate;
  },
  groupByYearDate(array){
    let byDate = [];
    try{
      if(S_Date.isLogDebug) console.log('groupByYearDate() array',array);

      let arraySorted = S_App.sortByDate(array).reverse();
      for(var i = 0; i < arraySorted.length; i++){
        let item = arraySorted[i];
        if(item!==undefined){
          item.dayDate = S_Date.getDayReference(item.date);
          item.yearDate = (new Date(item.date)).getFullYear();
          item.monthDate = (new Date(item.date)).getMonth();
        }
      }

      byDate = S_App.groupByKey(arraySorted, 'yearDate');
      if(S_Date.isLogDebug) console.log('groupByYearDate() grouped by yearDate',byDate);
    }catch(err){
      console.error(err);
    }
    return byDate;
  },
  groupByMonthDate(array){
    let byDate = [];
    try{
      if(S_Date.isLogDebug) console.log('groupByMonthDate() array',array);

      let arraySorted = S_App.sortByDate(array).reverse();
      for(var i = 0; i < arraySorted.length; i++){
        let item = arraySorted[i];
        if(item!==undefined){
          item.dayDate = S_Date.getDayReference(item.date);
          item.yearDate = (new Date(item.date)).getFullYear();
          item.monthDate = (new Date(item.date)).getMonth();
        }
      }

      byDate = S_App.groupByKey(arraySorted, 'monthDate');
      if(S_Date.isLogDebug) console.log('groupByMonthDate() grouped by monthDate',byDate);
    }catch(err){
      console.error(err);
    }
    return byDate;
  },

  formatDateForMaterial(dateInMillis:number){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let month = theDate.getMonth()+1;
      let day = theDate.getDate();
      date = theDate.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
    return date;
  },
  formatTimeForMaterial(dateInMillis:number){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let hour = theDate.getHours();
      let minute = theDate.getMinutes();
      date = (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute;
    }
    return date;
  },

  formatDateInString(dateInMillis:number):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: undefined });
    return null;
  },
  formatDateInStringWithName(dateInMillis:number):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' });
    return null;
  },
  formatDateInStringWithNameNoYear(dateInMillis:number):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined });
    return null;
  },
  formatHourInString(dateInMillis:number):string{
    if(dateInMillis){
      let min = (new Date(dateInMillis)).getMinutes();
      return (new Date(dateInMillis)).getHours() + 'h' + (min >=10 ? min : '0'+min);
    }
    return null;
  },

  formatDelayForNow(dateInMillis:number, reverse:boolean):number{
    if(!dateInMillis)
      return null;
    let ecart = dateInMillis - (new Date()).getTime();
    if(reverse)
      ecart = (new Date()).getTime() - dateInMillis;
    let days = ecart / 1000 / 60 / 60 / 24;
    return Math.round(days);
  },

  formatDateInMillis(dateInMaterial){
    if(!dateInMaterial)
      return undefined;
    return (new Date(dateInMaterial)).getTime();
  },
  formatDateInMillis2(dayInMaterial,timeInMaterial){
    if(!dayInMaterial)
      return undefined;

    if(S_Date.isLogDebug) console.log('formatDateInMillis2() ',dayInMaterial,timeInMaterial);
    let hours = 1;
    let minutes = 0;
    if(!!timeInMaterial){
      hours = timeInMaterial.split(':')[0];
      minutes = timeInMaterial.split(':')[1];
    }

    let dateDate = new Date(dayInMaterial);
    dateDate = new Date(dateDate.setHours(hours,minutes,0,0));
    if(S_Date.isLogDebug) console.log('formatDateInMillis2() ',dateDate);
    let dateMillis = dateDate.getTime();

    return dateMillis;
  },

  getCurrentDayReference(){
    let currentDayDate = new Date();
    currentDayDate = new Date(currentDayDate.setHours(1,0,0,0));  // fixe la date du jour a 1h du matin
    let currentDayMillis = currentDayDate.getTime();
    // if(S_Date.isLogDebug) console.log('getCurrentDayReference',currentDayMillis);
    return currentDayMillis;
  },
  getDayReference(dateInMillis:number):number{
    let dayDate = new Date(dateInMillis);
    dayDate = new Date(dayDate.setHours(1,0,0,0));  // fixe la date du jour a 1h du matin
    let dayMillis = dayDate.getTime();
    // if(S_Date.isLogDebug) console.log('getDayReference',dayMillis);
    return dayMillis;
  },
  getDayReferencesOfMonth(year:number,month:number){
    var date = new Date(year, month, 1);
    var daysInMillis = [];
    while (date.getMonth() === month) {
      let day = new Date(date);
      daysInMillis.push(S_Date.getDayReference(day.getTime()));
      date.setDate(date.getDate() + 1);
    }
    return daysInMillis;
  },
  getDayReferencesBetween(dateInMillis1:number,dateInMillis2:number){
    var date = new Date(dateInMillis1);
    if(S_Date.isLogDebug) console.log('-- S_Date -- getDayReferencesBetween()',date,new Date(dateInMillis2));
    var daysInMillis = [];
    while (date.getTime() <= dateInMillis2) {
      let day = new Date(date);
      if(S_Date.isLogDebug) console.log('-- S_Date -- getDayReferencesBetween()',day);
      daysInMillis.push(S_Date.getDayReference(day.getTime()));
      date = new Date(day.getTime() + ONE_DAY_IN_MILLIS);
    }
    return daysInMillis;
  }
};
export default S_Date;