// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record           from '../services/S_Record';
// import {I_Record}         from '../services/I_Record';
import XpBar                     from './XpBar';
import DiamondBar                from './DiamondBar';

// Styles
import './Daily.scss';

type DailyProps = {
  completed: boolean;
  stepNumber?: number;
  title: string;
  goal?: any;
  progress?:number;
  progressmax?:number;

  xp?:number;
  diamonds?:number;

  className?: string;
}

function Daily(props:DailyProps) {

  // props
  let {completed,stepNumber,title,goal,progress,progressmax,xp,diamonds,className} = props;
  const ElGoal = !!goal ? goal : null;

  return (
    <div className={'daily' + (completed ? ' achieved' : '')}>
      <div className="loots">
        {xp>0 ? <XpBar value={xp} /> : null}
        {diamonds>0 ? <DiamondBar value={diamonds} /> : null}
      </div>
      {!!stepNumber ? <span className="stepNumber">{stepNumber}</span> : null} 
      <strong>
        
        {title} 
        {!!goal ? <span className="goal"> : Acquérir {ElGoal}</span> : null}
      </strong>
      {progressmax!=undefined && progressmax>1 ? (
        <div className="progress">
          <div className="progressCursor" style={{width:Math.floor(progress*100/progressmax)+'%'}}></div>
          <span className={'progressLabel '+(progress==0?'black':'')}>{progress} sur {progressmax}</span>
        </div>
      ) : null}
    </div>
  );
}

export default Daily;
