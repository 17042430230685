import React,{useState,useEffect,useRef} from 'react';
import Slider from "react-slick";
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Record         from '../services/S_Record';
import {I_Record}        from '../services/I_Record';
import RecordsAccordions    from '../components/RecordsAccordions';

// Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './Dialog.scss';

type DialogAddRecordProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;

  type: string;
  prefImg: number;
  onClickEdit: any;
  records: I_Record[];
}

function DialogAddRecord(props:DialogAddRecordProps) {

  // props
  let {isDialogOpened,closeDialog,onClickValidate,type,prefImg,onClickEdit,records} = props;

  // state
  const [displayDetails, setdisplayDetails] = useState(false);

  // refs
  const slider = useRef(null);

  // chargement des donnees
  useEffect(() => {
    if(slider && slider.current) slider.current.slickGoTo(prefImg);
  }, []);

  // autres
  let recordsFilteredOnType = S_App.getByFilters(records,[{attribute:'type',value:type}]);
  let recordsByDate = S_Date.groupByDayDate(recordsFilteredOnType);
  let recordsOfDay = recordsByDate[S_Date.getCurrentDayReference()];
  let numberOfDaysSinceLastRecord = undefined;
  let days = Object.keys(recordsByDate);
  if(!!days && days.length>0){
    let lastDayInMillis = parseInt(days[0]);
    let ecartInMillis = Math.abs(S_Date.getCurrentDayReference() - lastDayInMillis);
    numberOfDaysSinceLastRecord = ecartInMillis / (1000 * 60 * 60 * 24);
  }
  let images = S_Record.IMAGES[type];
  let text = S_Record.TEXTS[type];
  
  // console.log('images',images);
  // console.log('prefImg',prefImg);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: prefImg!==undefined ? prefImg : 0,
    afterChange: (current) => {
      S_Record.storePrefImage(type,current,() => {});
    }
  };

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="text-center capitalize">{type}</div>
      </DialogTitle>
      <DialogContent>

        <div className="text-center">
          <div className={(!!days && days.length>0 ? 'text-normal' : 'text-big') + ' mv-2 text-center'}>
            {images ? (
              <Slider {...settings} ref={slider}>
                {images.map((image,index) => {
                  if(!image.img)
                    return <div key={index} className="text-center"><div className="circle">{image}</div></div>;
                  return (
                    <div key={index} className="text-center"><div className="circle"><img src={image.img} alt={image.alt} width="100%"/></div></div>
                  );
                })}
              </Slider>
            ) : null}
          </div>
          <div className={(!!days && days.length>0 ? 'text-normal' : 'text-big') + ' mv-05'}>
            {text}
          </div>
          <div className={'mv-05'}>
            notes persos... (WIP)
          </div>
          {!!days && days.length>0 ? (
            <div className="text-center mv-05">
              <div><strong>{numberOfDaysSinceLastRecord === 0 ? 'Aujourd\'hui' : 'Dernier il y a'}</strong></div>
              <div className={'numberOnCircle '+(numberOfDaysSinceLastRecord === 0 ? 'color-'+(recordsOfDay.length<=5 ? recordsOfDay.length : '5') : '')}>{numberOfDaysSinceLastRecord === 0 ? recordsOfDay.length : Math.round(numberOfDaysSinceLastRecord) + 'j'}</div>
            </div>
          ) : null}
          <Fab onClick={(e) => onClickValidate(type)} color="primary" variant="extended" className="mh-05 mv-1">
            <AddIcon className="mr-05"/>
            <i className="fab-label mr-05">Ajouter</i>
          </Fab>
          <br/>
          badges associés, prochains badges, n'apparait pas si aucun (pour laisser la place à la description) (WIP)
          {!!days && days.length>0 ? (
          <div className="text-right mv-1">
            <span className="cursor-pointer" onClick={(e) => setdisplayDetails(!displayDetails)}><u><i>Détails & Modifications</i></u></span>
            {displayDetails ? (
              <RecordsAccordions records={recordsFilteredOnType} editRecord={onClickEdit}/>
            ) : null}
          </div>
          ) : null}
        </div>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogAddRecord;
