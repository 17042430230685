import S_App    from   '../../services/S_App';
import S_Date   from   '../../services/S_Date';
import {I_Record} from   './I_Record';

import img_montgolfiere from '../illustrations/air-balloon.svg';
import img_volley from '../illustrations/beach-ball.svg';
import img_billiard from '../illustrations/billiard.svg';
import img_fusee from '../illustrations/startup.svg';
import img_noisette from '../illustrations/hazelnut.svg';
import img_door from '../illustrations/door.svg';
import img_elevator from '../illustrations/elevator.svg';
import img_tampon from '../illustrations/tampon.svg';
import img_rideaux from '../illustrations/curtain.svg';
import img_thermometer from '../illustrations/thermometer.svg';
import img_fil from '../illustrations/sewing.svg';
import img_tube from '../illustrations/test-tube.svg';
import img_rail from '../illustrations/railway.svg';




let TEST_RECORDS = [];

let S_Record = {
  isLogDebug: false, //S_App.isLogDebug, 

  STORAGE_KEY_RECORDS : 'visualisation',
  STORAGE_KEY_PREF_IMAGES : 'pref_img',
  TYPES_1: ['verrouillage','fausse-inspiration'],
  TYPES_2: ['vulve','vagin','anus','vessie','uretre'],
  TYPES_3: ['bateau','pont','gainage','chien/chat','hypopressif'],

  IMAGES: {
    'vulve': [{img:img_door,alt:'Portes coulissantes'},{img:img_rideaux,alt:'Rideaux'}],
    'vagin': [{img:img_elevator,alt:'Ascenseur'},{img:img_tampon,alt:'Tampon'},{img:img_fusee,alt:'Fusée'}],
    'anus': [{img:img_noisette,alt:'Noisette'},'Suppositoire'],
    'vessie': ['Pomme',{img:img_montgolfiere,alt:'Montgolfière'},{img:img_volley,alt:'Ballon'},{img:img_billiard,alt:'Boule de billard'}],
    'uretre': [{img:img_fil,alt:'Fil'},'Paille','Sarbacane',{img:img_thermometer,alt:'Thermomètre'},{img:img_tube,alt:'Tube'},{img:img_rail,alt:'Rail'}]
  },

  TEXTS: {
    'verrouillage': '1) Absorber/Attraper le périnée très haut 2) Gainer les abdos au nombril (=faire ventre plat) 3) PTAE = toux, éternuement, porter...',
    'fausse-inspiration': '1) Videz tout votre air en soufflant au max 2) Pincez le nez et fermez la bouche 3) Inspirez mais sans passage d’air = apnée ! Votre nombril va se rapprocher de la colonne vertébrale et remonter sous les côtes, reprendre de l\'air par le nez puis relâcher doucement son ventre. Cela peut faire tourner la tête donc max 2 fois d’affilée (Ex. Sous la douche ?)',
    'vulve': 'A l\'inspiration les portes coulissantes sont ouvertes, à l\'expiration je les referme et les maintient fermées',
    'vagin': 'A l\'inspiration je visualise l\'ascenseur au sol/rdc, à l\'expiration il monte le long de la colonne jusqu\'à la tête sans contracter les fesses !',
    'anus': 'A l\'inspiration je place la pêche au centre de l\'anus et sur le souffle : ça ressert sans contracter les fesses !',
    'vessie': 'A l\'inspiration je vais au fond du vagin, puis je monte à la verticale pour être dans la vessie. Sur le souffle alors je longe le ventre avec le ballon !',
    'uretre': 'A l\'inspiration, la bille est dans l\'urètre, à l\'expiration, elle remonte jusque dans la vessie',
    'bateau': 'Assise, pieds largeur bassin. Inspiration, verrouillage du périnée. Expiration, réalisation de l\'exo : 1)Mains sur genou on descend le dos en arrière, droit (« W ») 2) On lâche les mains en descendant 3) Mains lâchées, on soulève les pieds',
    'pont': 'Inspiration, verrouillage du périnée Expiration, monter en pont en déroulant la colonne tout du long. Inspiration je reste gainée, expiration je redéroule',
    'gainage': 'Epaules au-dessus des poignets 90° Bascule du bassin, dos étiré et aligné du bassin aux épaules On respire !!!!',
    'chien/chat': '1) Inspiration, on creuse du coccyx jusqu\'à la tête, vtb après vtb. Expiration on arrondit, du bas du dos vers la tête, vtb après vtb. 2) On rajoute une jambe tendue pied flexe sur l\'inspi / jambe pliée genou/menton expi 3) jambe et bras opposés',
    'hypopressif': 'Sur le dos, pieds largeur bassin. Une jambe repliée, 90°, mains sur le genou. Inspiration verrouillage, expiration pression sur le genou qui résiste ou sur l\'extérieur (oblique)'
  },

  records : [],

  fetch: (cb) => {
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- fetch()...');
    var records = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Record.STORAGE_KEY_RECORDS];
    if(records){
      S_Record.records = JSON.parse(records);
    }else{
      S_Record.records = TEST_RECORDS;
    }

    for(var i = 0; i < S_Record.records.length; i++){
      delete S_Record.records[i].ml;
    }
    S_Record.store(() => {});
      
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- fetch() records',S_Record.records);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Record.STORAGE_KEY_RECORDS] = JSON.stringify(S_Record.records);
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- store() records',S_Record.records); 
    if(cb)
      cb();
  },


  getById: (id:number|string) => {
    return S_App.getByID(S_Record.records,id);
  },
  groupByType(array:I_Record[]){
    let byType = [];
    try{
      // if(S_Record.isLogDebug) console.log('-- S_Record -- groupByType()',array);
      byType = S_App.groupByKey(array, 'type');
      if(S_Record.isLogDebug) console.log('-- S_Record -- groupByType() grouped by type',byType);
    }catch(err){
      console.error(err);
    }
    return byType;
  },



  add(dateInMillis,type,cb){
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- add()...');
      
    let dateNow = (new Date()).getTime();
    let newOne = {
      id: dateNow,
      date: dateInMillis,
      type: type
    };
    if(!S_Record.records)
      S_Record.records = [];
    S_Record.records.push(newOne);
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- add() added',newOne);
    S_Record.store(() => {
      if(cb) cb();
    });
  },
  update(value,field,record:I_Record,cb){
    if(!S_Record.records || S_Record.records.length===0 || !record){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Record.records.length; i++){
      if(S_Record.records[i].id === record.id){
        foundData = S_Record.records[i];
        if('date'===field){
          // value doit etre en millis
          foundData[field] = value!==undefined ? (new Date(value)).getTime() : undefined;
        }else{
          foundData[field] = value;
        }
        break;
      }
    }
    if(S_Record.isLogDebug) console.log('Update',field,'as',value,'...',foundData);
    S_Record.store(() => {
      if(cb) cb(foundData);
    });
  },
  remove(record:I_Record,cb){
    if(!S_Record.records || S_Record.records.length===0 || !record){
      if(cb) cb();
      return;
    }
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- remove() delete',record,'...'); 
    let foundNoteIndex = S_App.getIndexOfByID(S_Record.records,record.id);
    if(foundNoteIndex !== -1){
      S_Record.records.splice(foundNoteIndex,1);
      if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- remove() deleted'); 
    }else{
      if(S_Record.isLogDebug) console.warn('-- S_Record.tsx -- remove() not found');
    }
    S_Record.store(() => {
      if(cb) cb();
    });
  },



  // renvoie le premier enregistrement, pour faire demarrer la chronologie a cette date
  getFirstRecord(array:I_Record[]){
    if(!array || array.length===0)
      return undefined;
      
    // console.log('getFirstRecord',array);
    let recordsSorted = S_App.sortByDate(array);
    if(S_Record.isLogDebug) console.log('-- S_Record -- getFirstRecord() recordsSorted',recordsSorted);
    let recordsByDate = S_Date.groupByDayDate(recordsSorted);
    if(S_Record.isLogDebug) console.log('-- S_Record -- getFirstRecord() recordsByDate',recordsByDate);
    let dayReferences = Object.keys(recordsByDate).sort();
    if(S_Record.isLogDebug) console.log('-- S_Record -- getFirstRecord() dayReferences',dayReferences);
    let firstRecords = recordsByDate[dayReferences[0]];
    if(S_Record.isLogDebug) console.log('-- S_Record -- getFirstRecord() firstRecords',firstRecords[0],firstRecords);
    return firstRecords[0];
  },

  getRecordsOfType2(array:I_Record[]):I_Record[]{
    let results = [];
    let recordsByType = S_Record.groupByType(array);
    for(var t = 0; t < S_Record.TYPES_2.length; t++){
      let type = S_Record.TYPES_2[t];
      let records = recordsByType[type];
      // if(S_Record.isLogDebug) console.log('-- S_Record -- getRecordsOfType2()',type,records);
      if(records)
        results.push(...records);
    }
    if(S_Record.isLogDebug) console.log('-- S_Record -- getRecordsOfType2()',results);
    return results;
  },
  getRecordsOfType3(array:I_Record[]):I_Record[]{
    let results = [];
    let recordsByType = S_Record.groupByType(array);
    for(var t = 0; t < S_Record.TYPES_3.length; t++){
      let type = S_Record.TYPES_3[t];
      let records = recordsByType[type];
      // if(S_Record.isLogDebug) console.log('-- S_Record -- getRecordsOfType2()',type,records);
      if(records)
        results.push(...records);
    }
    if(S_Record.isLogDebug) console.log('-- S_Record -- getRecordsOfType3()',results);
    return results;
  },



  fetchPrefImage: (key,cb) => {
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- fetchPrefImage() key',key);
    var value = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Record.STORAGE_KEY_PREF_IMAGES + '_' + key];
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- fetchPrefImage() key',key,value);
    if(cb)
      cb(value);
  },
  storePrefImage: (key,value,cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Record.STORAGE_KEY_PREF_IMAGES + '_' + key] = value;
    if(S_Record.isLogDebug) console.log('-- S_Record.tsx -- storePrefImage() key',key,value); 
    if(cb)
      cb();
  }

};
export default S_Record;