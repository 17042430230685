// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques
// import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Record           from '../services/S_Record';
import {I_Record}         from '../services/I_Record';

// Styles
import './Calendar.scss';

type CalendarProps = {
  records: I_Record[];
  category: number;                  // 1, 2, 3
}

function Calendar(props:CalendarProps) {

  // props
  let {records,category} = props;

  // state



  // autres variables

  // let currentDayMillis = S_Date.getCurrentDayReference();
  let currentYear = (new Date()).getFullYear();
  let currentMonth = (new Date()).getMonth();
  // let currentDay = S_Date.getCurrentDayReference();

  if(!records)
    return null;

  let recordsByDate = S_Date.groupByDayDate(records);
  if(!recordsByDate)
    return null;

  let firstRecord = S_Record.getFirstRecord(records);
  let firstYear = firstRecord ? (new Date(parseInt(firstRecord.date))).getFullYear() : undefined;
  let firstMonth = firstRecord ? (new Date(parseInt(firstRecord.date))).getMonth() : undefined;
  let recordsByYear = S_Date.groupByYearDate(records);

  let byYears = [];
  if(firstYear){
    byYears.push({year:firstYear,records:recordsByYear[firstYear]});
    for(var y = firstYear; y < currentYear; y++){
      byYears.push({year:y,records:recordsByYear[y]});
    }
  }

  let types = [];
  if(category===1) types = S_Record.TYPES_1;
  if(category===2) types = S_Record.TYPES_2;
  if(category===3) types = S_Record.TYPES_3;


  return (
    <table className="calendar mv-1">

      <thead>
        <tr>
          <th>
            <strong></strong>
          </th>
          {types.map((type,index) => <td className="text-center capitalize" key={index}><strong>{type}</strong></td>)}
        </tr>
      </thead>

      {byYears.map((byYear,index) => {
        let recordsOfYear = byYear.records;
        let recordsByMonth = S_Date.groupByMonthDate(recordsOfYear);
        if(!recordsByMonth)
          return null;
        // let months = [0,1,2,3,4,5,6,7,8,9,10,11];
        let byMonths = [];
        byMonths.push({month:0,records:recordsByMonth[0]});
        for(var m = 0; m < 12; m++){
          byMonths.push({month:m,records:recordsByMonth[m]});
        }

        return (
          <tbody key={index}>
            {byMonths.reverse().map((byMonth,index2) => {
              // let records = byMonth.records;
              // let recordsByDate = S_Date.groupByDayDate(records);
              // let recordsDates = Object.keys(recordsByDate);
              let monthFormatted = (new Date((new Date()).setMonth(byMonth.month))).toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: undefined, weekday: undefined });

              if(firstYear===byYear.year && firstMonth>byMonth.month+1)
                return null;
              if(currentYear===byYear.year && currentMonth<byMonth.month)
                return null;

              let daysOfTheMonth = S_Date.getDayReferencesOfMonth(byYear.year,byMonth.month).reverse();

              return [
                <tr key={index+'-'+index2} className="monthRow">
                  <td className="text-center">
                    <small><i>{byYear.year}<br/>{monthFormatted}</i></small>
                  </td>
                  {types.map((type,index3) => <td key={index3}></td>)}
                </tr>,

                <>
                {daysOfTheMonth.map((dayInMillis,index3) => {
                  
                  if(dayInMillis > S_Date.getCurrentDayReference())
                    return null;

                  let date = new Date(parseInt(dayInMillis));
                  let dateFormatted1 = date.toLocaleDateString("fr-FR", { year: undefined, month: undefined, day: 'numeric', weekday: undefined });
                  let dateFormatted2 = date.toLocaleDateString("fr-FR", { year: undefined, month: undefined, day: undefined, weekday: 'short' });
                  let dayRecords = recordsByDate[dayInMillis];
                  return [
                    <tr key={index+'-'+index2+'-'+index3} className="text-left">
                      <td className="text-center capitalize"><small>{dateFormatted2}<br/>{dateFormatted1}</small></td>
                      {types.map((type,index4) => {
                        let totalDayType = 0;
                        if(!!dayRecords){
                          for(var i = 0; i < dayRecords.length; i++){
                            let record = dayRecords[i];
                            if(record.type !== type)
                              continue;
                            totalDayType++;
                          }
                        }
                        return (
                          <td key={index4} className="text-center">
                            <div className={'numberOnCircle small color-'+(totalDayType<=5 ? totalDayType : '5')}>{totalDayType}</div>
                          </td>
                        );
                      })}
                    </tr>
                  ];
                })}
                </>
              ];
            })}
          </tbody>

        );
      })}
    </table>
  );
}

export default Calendar;
