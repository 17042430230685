import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
import S_Gaming         from '../services/S_Gaming';
// import S_Record         from '../services/S_Record';
// import {I_Record}        from '../services/I_Record';
import XpBar      from '../elements/XpBar';

// Styles
// import './Dialog.scss';

type DialogDetailsXPProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  xp: number;
  level: number;
  steps: any;
}

function DialogDetailsXP(props:DialogDetailsXPProps) {

  // props
  let {isDialogOpened,closeDialog,xp,level,steps} = props;

  // state

  // autres

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="text-center capitalize">Points d'expérience</div>
        <div className="text-center capitalize"><XpBar value={xp} /> Niveau {level}</div>
      </DialogTitle>
      <DialogContent>

        <div>
          <p>Les points d'expérience représentent vos efforts dans le jeu. Ils permettent de passer des niveaux, qui débloquent certaines fonctionnalités ou personnalisations.</p>

          <h4>Points continus</h4>
          <p>Chaque exercice réalisé donne <XpBar value={1} />.</p>
          <p>Chaque <i>série<sup>¤</sup></i> donne <XpBar value={2} />.</p>
          <p><i>(WIP) Chaque collection complétée donne <XpBar value={20} />.</i></p>

          <h4>Campagnes</h4>
          <p>Chaque palier de campagne complété donne un nombre de <XpBar value={1} /> dépendant directement du niveau du palier.</p>

          <br/>
          <p><small>¤ Série : 5 fois un même exercice sur un même jour.</small></p>
        </div>
      

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogDetailsXP;
