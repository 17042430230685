import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record         from '../services/S_Record';
// import {I_Record}        from '../services/I_Record';
// import XpBar      from '../elements/XpBar';
import DiamondBar from '../elements/DiamondBar';

// Styles
// import './Dialog.scss';

type DialogDetailsDiamondProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close
  diamonds: number;
}

function DialogDetailsDiamond(props:DialogDetailsDiamondProps) {

  // props
  let {isDialogOpened,closeDialog,diamonds} = props;

  // state

  // autres

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="text-center capitalize">Lotus</div>
        <div className="text-center capitalize"><DiamondBar value={diamonds} /></div>
      </DialogTitle>
      <DialogContent>

        <div>
          <p>Les lotus peuvent être échangés contre des bonus et des personnalisations.</p>
          <p>Ils sont obtenus en passant des niveaux, en complétant des campagnes, et en remplissant des défis quotidiens.</p>

          <h4>Gains continus</h4>
          <p>Chaque niveau passé donne un nombre de <DiamondBar value={1} /> dépendant directement du niveau du palier.</p>
          <p><i>(WIP) Chaque collection complétée donne <DiamondBar value={20} /></i></p>
          <p><i>(WIP) Chaque video regardée donne <DiamondBar value={1} /></i></p>

          <h4>Campagnes</h4>
          <p>Chaque palier de campagnes complété donne un nombre de <DiamondBar value={1} /> dépendant directement du niveau du palier.</p>

          <h4>Défis quotidiens</h4>
          <p>Une <i>série<sup>¤</sup></i> de type visualisation donne <DiamondBar value={1} />.</p>
          <p>Une <i>série<sup>¤</sup></i> de type fitness donne <DiamondBar value={2} />.</p>
          <p>Une <i>série<sup>¤</sup></i> sur 5 types visualisation donne <DiamondBar value={3} />.</p>
          <p>Une <i>série<sup>¤</sup></i> sur 5 types fitness donne <DiamondBar value={6} />.</p>
          <ul>
            <li>(WIP) acheter un item = <DiamondBar value={5} /></li>
            <li>(WIP) regarder une pub = <DiamondBar value={2} /></li>
          </ul>

          <h4>Défis occasionnels</h4>
          <ul>
            <li>(WIP) une <i>série<sup>¤</sup></i> sur 5 types visualisation le 01/01 = <DiamondBar value={20} /></li>
            <li>(WIP) une <i>série<sup>¤</sup></i> sur 5 types visualisation le 29/02 = <DiamondBar value={40} /></li>
            <li>(WIP) une <i>série<sup>¤</sup></i> sur 5 types visualisation le 01/05 = <DiamondBar value={20} /></li>
            <li>(WIP) une <i>série<sup>¤</sup></i> sur 5 types visualisation le 14/07 = <DiamondBar value={20} /></li>
          </ul>


          <br/>
          <p><small>¤ Série : 5 fois un même exercice sur un même jour.</small></p>
        </div>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogDetailsDiamond;
