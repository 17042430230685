import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record         from '../services/S_Record';
// import {I_Record}        from '../services/I_Record';

// Styles
// import './Dialog.scss';

type DialogDetailsCategory3Props = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close
}

function DialogDetailsCategory3(props:DialogDetailsCategory3Props) {

  // props
  let {isDialogOpened,closeDialog} = props;

  // state

  // autres

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="text-center capitalize">Fitness</div>
      </DialogTitle>
      <DialogContent>

        <p>
          Quelques propositions d'exercices pour la gymnastique abdominale... (Pas de bon périnée sans de bons abdos ! / Pilates - Yoga)
        </p>
      

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogDetailsCategory3;
