// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// Composants génériques
// import S_App      from '../../services/S_App';

// Composants propres
import S_Record    from '../services/S_Record';

// Styles
// import './ListTypes.scss';

type ListTypesProps = {
  onClick: any;
  onClickHelp: any;
}

function ListTypes(props:ListTypesProps) {

  // props
  let {onClick,onClickHelp} = props;

  // state



  // autres variables



  return (
    <>
    <Paper elevation={1} className="text-center mv-05">
      <h2 className="m-05">PTAE</h2>
      <div><HelpOutlineIcon className="cursor-pointer" onClick={(e) => onClickHelp(1)} /></div>
      {S_Record.TYPES_1.map((type,index) => (
        <Fab key={index} onClick={(e) => onClick(type)} color="primary" variant="extended" className="m-05">
          <AddIcon className="mr-05"/>
          <i className="fab-label mr-05 ">{type}</i>
        </Fab>
      ))}
    </Paper>
    <Paper elevation={1} className="text-center mv-05">
      <h2 className="m-05">Visualisations</h2>
      <div><HelpOutlineIcon className="cursor-pointer" onClick={(e) => onClickHelp(2)} /></div>
      {S_Record.TYPES_2.map((type,index) => (
        <Fab key={index} onClick={(e) => onClick(type)} color="primary" variant="extended" className="m-05">
          <AddIcon className="mr-05"/>
          <i className="fab-label mr-05">{type}</i>
        </Fab>
      ))}
    </Paper>
    <Paper elevation={1} className="text-center mv-05">
      <h2 className="m-05">Fitness</h2>
      <div><HelpOutlineIcon className="cursor-pointer" onClick={(e) => onClickHelp(3)} /></div>
      {S_Record.TYPES_3.map((type,index) => (
        <Fab key={index} onClick={(e) => onClick(type)} color="primary" variant="extended" className="m-05">
          <AddIcon className="mr-05"/>
          <i className="fab-label mr-05">{type}</i>
        </Fab>
      ))}
    </Paper>
    </>
  );
}

export default ListTypes;
