// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
// import FlareIcon from '@material-ui/icons/Flare';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import SpaIcon from '@material-ui/icons/Spa';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record           from '../services/S_Record';
// import {I_Record}         from '../services/I_Record';

// Styles
// import './Xp.scss';

type DiamondBarProps = {
  value: number;
  theme?: string;  // bar, number
  className?: string;
}

function DiamondBar(props:DiamondBarProps) {

  // props
  let {value,theme,className} = props;

  if(theme === 'bar')
    return <div className={'diamond-status bar ' + (className ? className : '')}><span>{value}</span> <SpaIcon /></div>;

  if(theme === 'statusbar')
    return <div className={'diamond-status statusbar ' + (className ? className : '')}><span>{value}</span> <SpaIcon /></div>;

  return <div className="diamond-status"><span>{value}</span> <SpaIcon /></div>;
}

export default DiamondBar;
