// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record           from '../services/S_Record';
// import {I_Record}         from '../services/I_Record';
import DiamondBar                from './DiamondBar';

// Styles
import './GameStatusBar.scss';

type GameStatusBarProps = {
  diamonds: number;

  className?: string;
  onClick?: any;
}

function GameStatusBar(props:GameStatusBarProps) {

  // props
  let {diamonds,className,onClick} = props;

  return (
    <div className={'game-status-bar '+className} onClick={onClick}>
      <DiamondBar value={diamonds} theme="statusbar" className={''}/>
    </div>
  );
}

export default GameStatusBar;
