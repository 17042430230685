// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record           from '../services/S_Record';
// import {I_Record}         from '../services/I_Record';

// Styles
import './XpProgress.scss';

type XpProgressProps = {
  percent: number;

  className?: string;
}

function XpProgress(props:XpProgressProps) {

  // props
  let {percent,className} = props;

  return (
    <div className={'text-center xpProgress '+className}>
      <div className="xpProgressPercent" style={{width:percent+'%'}}></div>
      <div className="item">
        <div className="value">{percent}%</div>
      </div>
    </div>
  );
}

export default XpProgress;
