// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

// Composants génériques
// import S_App      from '../../services/S_App';
// import S_Date     from '../../services/S_Date';

// Composants propres
// import S_Record           from '../services/S_Record';
// import {I_Record}         from '../services/I_Record';
import XpBar                     from './XpBar';
import XpProgress                     from './XpProgress';

// Styles
import './GameAvatar.scss';

type GameAvatarProps = {
  xp: number;
  level: number;
  levelprogress: number;

  className?: string;
  onClick?: any;
}

function GameAvatar(props:GameAvatarProps) {

  // props
  let {xp,level,levelprogress,className,onClick} = props;

  return (
    <div className={'game-avatar '+className} onClick={onClick}>
      <Avatar className="large">
        <PersonIcon/>
      </Avatar>
      <div className="level"><span>Niveau</span>{level}</div>
      <XpProgress percent={levelprogress} className={''} />
    </div>
  );
}

export default GameAvatar;
